import React from "react";
import Header from "../../components/Header";

const Privacy = () => (
  <React.Fragment>
      <Header />
      <section className="section">
        <div className="container mb40">
          <div className="col-12 ">
            <h1 className="heading-lrg primary-color text-center ">
              Privacy Policy
            </h1>
            <div className="mt40">
              <h4 className="heading-sml primary-color ">
                1 – Privacy Policy of CoderTest
              </h4>
              <div>
                <p className="paragraph mt10 mb10">
                  CoderTest (“we”, “our” or “us”) operates the CoderTest website
                  https://codertest.io (here and after referred to as the
                  “Website”) and provides the CoderTest and other CoderTest
                  services (referred together as the “Services”).
                </p>
                <p className="paragraph mt10 mb10">
                  This Privacy Policy is expressly incorporated into our Terms
                  of Service for the Website.
                </p>
              </div>
            </div>
            <div className="mt40">
              <h4 className="heading-sml primary-color ">
                2 – What Does This Privacy Policy Cover?
              </h4>
              <div>
                <p className="paragraph mt10 mb10">
                  When using our Services or accessing the Website, you may
                  provide us with your personally identifiable information
                  (“Personal Information”). This Privacy Policy describes how we
                  collect and use the personal information you may provide on
                  our Website or using our Services. It also describes the
                  choices available to you regarding our use of your personal
                  information.
                </p>
                <p className="paragraph mt10 mb10">
                  If you provide us personal information about others, or if
                  others give us your information, we will only use that
                  information for the specific reason for which it was provided
                  to us.
                </p>
                <p className="paragraph mt10 mb10">
                  This Privacy Policy is also applicable to any personal
                  information that our business partners, subsidiaries and
                  affiliates may share with us or that we may share with them.
                </p>
              </div>
            </div>
            <div className="mt40">
              <h4 className="heading-sml primary-color ">
                3 – What Information Do We Collect?
              </h4>
              <div>
                <h6 className="subheading primary-color ">
                  Slack Team Information:
                </h6>
                <p className="paragraph mt10 mb10">
                  When you or another user in your Slack team enables the
                  CoderTest for your team, we receive the name of your team and
                  your Slack team id from the Slack API.
                </p>
                <h6 className="subheading primary-color ">
                  Slack User Information:
                </h6>
                <p className="paragraph mt10 mb10">
                  If you are a member of a Slack team that has enabled the
                  CoderTest, we receive your Slack user id from the Slack API.
                </p>
                <h6 className="subheading primary-color ">
                  Billing information
                </h6>
                <p className="paragraph mt10 mb10">
                  CoderTest requests the following billing information to
                  proceed the payment for the service:
                </p>

                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-minus" />
                    </span>
                    Credit/Debit card number;
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-minus" />
                    </span>
                    CVC;
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-minus" />
                    </span>
                    Card expiration date;
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-minus" />
                    </span>
                    Card owner name;
                  </li>
                </ul>
                <p className="paragraph mt10 mb10">
                  CoderTest doesn't store or manage this information in any way.
                  We transfer it to the third-party payment provider Stripe. All
                  the billing data is stored and managed on Stripe’s side.
                  CoderTest handles its management via API tokens provided by
                  Stripe.
                </p>
                <h6 className="subheading primary-color ">
                  Web browser cookies:
                </h6>
                <p className="paragraph mt10 mb10">
                  Our Website may use “cookies” to enhance your user experience.
                  Your web browser may place cookies on their hard drive for
                  record-keeping purposes and sometimes to track information
                  about them. You may choose to set your web browser to reject
                  cookies, or to alert you when cookies are being sent. If you
                  do so, please note that some parts of the Website may not
                  function properly.
                </p>
                <p className="paragraph mt10 mb10">
                  Please also note that “cookies” are used to collect general
                  usage and volume statistical information that does not include
                  personal information.
                </p>
              </div>
            </div>
            <div className="mt40">
              <h4 className="heading-sml primary-color ">
                4 – How, and With Whom, Is Collected Information Shared?
              </h4>
              <div>
                <p className="paragraph mt10 mb10">
                  We may store some of your personal information on the side of
                  our service providers, business partners, subsidiaries and
                  affiliates when we use their services. Their list and services
                  include, but not limited to:
                </p>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-minus" />
                    </span>
                    Mongo DB Atlas - cloud database where we store data
                    mentioned in the item #3 of this Privacy Policy;
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-minus" />
                    </span>
                    Stripe - payment provider that manages payments for
                    CoderTest services;
                  </li>
                </ul>
                <p className="paragraph mt10 mb10">
                  We do not sell, lease or transfer your personal information to
                  third parties, but we may and sometimes shall disclose your
                  personal information if one of the following circumstances
                  occur:
                </p>
                <ol>
                  <li>
                    As required by law such as to comply with a subpoena, or
                    similar legal process;
                  </li>
                  <li>
                    When we believe in good faith that disclosure is necessary
                    to protect our rights, protect your safety or the safety of
                    others, investigate fraud, or respond to a government
                    request;
                  </li>
                  <li>
                    If we are involved in a merger, acquisition, or sale of all
                    or a portion of our assets, you will be notified via email
                    and/or a prominent notice on our Website of any change in
                    ownership or uses of your personal information, as well as
                    any choices you may have regarding your personal
                    information;
                  </li>
                  <li>
                    To any other third party with your prior consent to do so.
                  </li>
                </ol>
              </div>
            </div>
            <div className="mt40">
              <h4 className="heading-sml primary-color ">
                5 – What Information About Me Is Public?
              </h4>
              <div>
                <p className="paragraph mt10 mb10">
                  If you are a member of a Slack team that has enabled the
                  CoderTest, we may publicly present on our Website your Slack
                  team name.
                </p>
              </div>
            </div>
            <div className="mt40">
              <h4 className="heading-sml primary-color ">
                6 – Is Information About Me Secure?
              </h4>
              <div>
                <p className="paragraph mt10 mb10">
                  The security of your personal information is important to us.
                  We follow generally accepted industry standards to protect the
                  personal information submitted to us, both during transmission
                  and once we receive it. No method of transmission over the
                  Internet, or method of electronic storage, is 100% secure,
                  however. Therefore, we cannot guarantee its absolute security.
                </p>
                <p className="paragraph mt10 mb10">
                  Non-public Personal Information that you share with us will be
                  protected using industry-standard techniques. We seek to
                  protect your non-public Personal Information to ensure that it
                  is kept private; however, we cannot guarantee its security.
                  Unauthorized entry or use, hardware or software failure, and
                  other factors, may compromise the security of user information
                  at any time.
                </p>
                <p className="paragraph mt10 mb10">
                  If you have any questions about security on our Website,
                  please contact us at
                  <a href="mailto:support@codertest.io">support@codertest.io</a>
                  .
                </p>
              </div>
            </div>
            <div className="mt40">
              <h4 className="heading-sml primary-color ">
                7 – How Can I Delete Information Kept by You?
              </h4>
              <div>
                <p className="paragraph mt10 mb10">
                  Should you ever decide to delete your account, you may do so
                  by emailing
                  <a href="mailto:support@codertest.io">support@codertest.io</a>
                  If you terminate your Account, any association between your
                  Account and information we store will no longer be accessible
                  through your Account. However, given the nature of sharing on
                  the Services, any public activity on your Account prior to
                  deletion will remain stored on our servers and will remain
                  accessible to the public.
                </p>
                <p className="paragraph mt10 mb10">
                  To review and update your personal information to ensure it is
                  accurate, contact us at
                  <a href="mailto:support@codertest.io">support@codertest.io</a>
                  .
                </p>
              </div>
            </div>
            <div className="mt40">
              <h4 className="heading-sml primary-color ">
                8 – What Happens When There Are Changes to this Privacy Policy?
              </h4>
              <div>
                <p className="paragraph mt10 mb10">
                  We may update and amend this Privacy Policy from time to time.
                  Use of information we collect now is subject to the Privacy
                  Policy in effect at the time such information is used. If we
                  amend this Privacy Policy, we will notify you by posting an
                  announcement on the Website. If we make any material changes
                  to this Privacy Policy, we may also choose at our discretion
                  to notify you through the Services and/or by sending you an
                  email.
                </p>
                <p className="paragraph mt10 mb10">
                  You are bound by any update and amendment to the Privacy
                  Policy when you keep using our Website and our Services after
                  such update or amendment has been first posted on the Website.
                </p>
                <p className="paragraph mt10 mb10">
                  We encourage you to periodically review this page for the
                  latest information on our privacy practices.
                </p>
              </div>
            </div>
            <div className="mt40">
              <h4 className="heading-sml primary-color ">
                9 – What If I Have Questions or Concerns?
              </h4>
              <div>
                <p className="paragraph mt10 mb10">
                  If you have any questions or concerns regarding this Privacy
                  Policy and our practices in accordance with thereof, please
                  send us a detailed message at
                  <a href="mailto:support@codertest.io">support@codertest.io</a>
                  . We will make every effort to resolve your concerns.
                </p>
                <p className="paragraph mt40 mb10">
                  Effective Date: July 18, 2019
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
  </React.Fragment>
);

export default Privacy;
