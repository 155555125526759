import React from "react";
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import CodeExecutionImg from "../../img/code_execution.png";
import FullScreenshotImg from "../../img/full_screenshot.png";
import InterviewerNotesImg from "../../img/interviewer_notes.png";
import PlaybackImg from "../../img/playback.png";
import SlackWebhookImg from "../../img/slack-webhook.png";
import QuestionsLibraryImg from "../../img/questions_library.png";
import BrowserExecutionImg from "../../img/frontend_execution.png";

const stopWords = ["for", "and"];
const capitalize = s => {
  if (typeof s !== "string") return s;
  if (stopWords.includes(s)) return s;
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const generateCompanyName = name =>
  name
    .split("-")
    .map(item => capitalize(item))
    .join(" ");

class Landing extends React.Component {
  render() {
    const { path, params } = this.props.match;
    return (
      <React.Fragment>
        <div className="coral-black">
          <Header />
          <section className="section">
            <div className="container mb40">
              <div className="col-12 text-center">
                <h1 className="heading-lrg primary-color launchaco-builder-hoverable">
                  {path === "/"
                    ? "Meet your hiring goals."
                    : `Meet your hiring goals at ${generateCompanyName(
                        params.name
                      )}`}
                </h1>
                <h2 className="subheading secondary-color mt20 launchaco-builder-hoverable">
                  Instantly evaluate a candidate's abilities without paying for
                  an expensive on-site interview.
                </h2>
                <div className="mt40">
                  <a href="https://app.codertest.io/coderoom/interviews"
                    className="button mobile-text-center mt10 launchaco-builder-hoverable mobile-text-center accent-bg primary-color"
                  >
                    <span> Start Interviewing </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="container-lrg">
              <div className="col-12">
                <div className="browser mt75 launchaco-builder-hoverable">
                  <div className="mask">
                    <img
                      src={FullScreenshotImg}
                      alt="Screenshot of CoderTest in Browser"
                      className="mask-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section
          id="feature-1"
          className="section coral-white"
          sitemeta="[object Object]"
          activepage="Landing"
        >
          <div className="container-lrg flex">
            <div className="col-4 text-center flex flex-column center-horizontal">
              <i className="icon mobile-center-icon secondary-bg launchaco-builder-hoverable">
                <svg
                  className="svg-fill"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25 25"
                >
                  <path d="M23.707 22.293l-6.825-6.825a9.519 9.519 0 1 0-1.414 1.414l6.825 6.825a1 1 0 0 0 1.414-1.414zM9.5 17A7.5 7.5 0 1 1 17 9.5 7.508 7.508 0 0 1 9.5 17z" />
                </svg>
              </i>
              <h3 className="bold primary-color launchaco-builder-hoverable">
                Identify Skills
              </h3>
              <p className="paragraph secondary-color launchaco-builder-hoverable">
                Uncover a candidates true skills. Watch your candidate code in
                real-time and follow their thought process.
              </p>
            </div>
            <div className="col-4 text-center flex flex-column center-horizontal">
              <i className="icon mobile-center-icon secondary-bg launchaco-builder-hoverable">
                <svg
                  className="svg-fill"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25 25"
                >
                  <path d="M12.5 0A12.5 12.5 0 1 0 25 12.5 12.5 12.5 0 0 0 12.5 0zm4.353 16.853a.5.5 0 0 1-.707 0l-4-4-.004-.005a.497.497 0 0 1-.142-.346V6.5a.5.5 0 0 1 1 0v5.793l3.853 3.853a.5.5 0 0 1 0 .707z" />
                </svg>
              </i>
              <h3 className="bold primary-color launchaco-builder-hoverable">
                Save time
              </h3>
              <p className="paragraph secondary-color launchaco-builder-hoverable">
                Reduce the time spent by your developers interviewing candidates
                and give them time back to build products.
              </p>
            </div>
            <div className="col-4 text-center flex flex-column center-horizontal">
              <i className="icon mobile-center-icon secondary-bg launchaco-builder-hoverable">
                <svg
                  className="svg-fill"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25 25"
                >
                  <path d="M10 4a3 3 0 1 1 3 3 3 3 0 0 1-3-3zm10 2a3 3 0 1 0-3-3 3 3 0 0 0 3 3zM6 6a3 3 0 1 0-3-3 3 3 0 0 0 3 3zm1 10.5V13a5.987 5.987 0 0 1 2.31-4.721A4.979 4.979 0 0 0 1 12v2.5A1.5 1.5 0 0 0 2.5 16H3v4.5A1.5 1.5 0 0 0 4.5 22h3A1.5 1.5 0 0 0 9 20.5v-1.55a2.505 2.505 0 0 1-2-2.45zM20 7a4.96 4.96 0 0 0-3.31 1.279A5.987 5.987 0 0 1 19 13v3.5a2.505 2.505 0 0 1-2 2.45v1.55a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V16h.5a1.5 1.5 0 0 0 1.5-1.5V12a5 5 0 0 0-5-5zm-2 9.5V13a5 5 0 1 0-10 0v3.5A1.5 1.5 0 0 0 9.5 18h.5v5.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V18h.5a1.5 1.5 0 0 0 1.5-1.5z" />
                </svg>
              </i>
              <h3 className="bold primary-color launchaco-builder-hoverable">
                Standardize
              </h3>
              <p className="paragraph secondary-color launchaco-builder-hoverable">
                Create a consistent hiring process to reduce interview bias and
                maintain a high bar for talent.
              </p>
            </div>
          </div>
        </section>
        <section
          id="feature-2"
          className="section coral-white"
          sitemeta="[object Object]"
          activepage="Landing"
        >
          <div className="container-lrg flex">
            <div className="flex col-6 flex-column mobile-text-center center-vertical">
              <h3 className="bold primary-color launchaco-builder-hoverable">
                Code Execution
              </h3>
              <p className="paragraph secondary-color launchaco-builder-hoverable">
                We support the code execution of 14 languages including Bash, C,
                C#, C++, Elixir, F#, Golang, Java, JavaScript, Perl, PHP,
                Python, Ruby and Scala.
              </p>
            </div>
            <div className="col-6 flex center-horizontal center-vertical">
              <div className="launchaco-builder-hoverable">
                <div className="">
                  <img
                    src={CodeExecutionImg}
                    alt="Screenshot of CoderTest code execution"
                    className="custom-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="feature-7"
          className="section coral-white"
          sitemeta="[object Object]"
          activepage="Landing"
        >
          <div className="container-lrg flex">
            <div className="flex col-6 flex-column mobile-text-center center-vertical">
              <h3 className="bold primary-color launchaco-builder-hoverable">
                Browser Execution
              </h3>
              <p className="paragraph secondary-color launchaco-builder-hoverable">
                Test your candidate's React, Angular and Vue skills in our
                browser environment. We support any package that can be found on{" "}
                <a href="https://www.npmjs.com/">NPM</a>. And Hot Module
                Reloading is built in, so you won't have to press run for every
                change.
              </p>
            </div>
            <div className="col-6 flex center-horizontal center-vertical">
              <div className="launchaco-builder-hoverable">
                <div className="">
                  <img
                    src={BrowserExecutionImg}
                    alt="Screenshot of CoderTest browser code execution"
                    className="custom-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="feature-3"
          className="section coral-white"
          sitemeta="[object Object]"
          activepage="Landing"
        >
          <div className="container-lrg flex">
            <div className="flex col-6 flex-column mobile-text-center center-vertical">
              <h3 className="bold primary-color launchaco-builder-hoverable">
                Interviewer Notes
              </h3>
              <p className="paragraph secondary-color launchaco-builder-hoverable">
                Take notes or save snippets of code during the interview. Notes
                are saved with the interview and can be reviewed at any time.
              </p>
            </div>
            <div className="col-6 flex center-horizontal center-vertical">
              <div className="launchaco-builder-hoverable">
                <div className="">
                  <img
                    src={InterviewerNotesImg}
                    alt="Screenshot of CoderTest Interviewer Notes"
                    className="custom-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="feature-4"
          className="section coral-white"
          sitemeta="[object Object]"
          activepage="Landing"
        >
          <div className="container-lrg flex">
            <div className="flex col-6 flex-column mobile-text-center center-vertical">
              <h3 className="bold primary-color launchaco-builder-hoverable">
                Interview Playback
              </h3>
              <p className="paragraph secondary-color launchaco-builder-hoverable">
                Replay and review every interview (including code execution
                output) with your team.
              </p>
            </div>
            <div className="col-6 flex center-horizontal center-vertical">
              <div className="launchaco-builder-hoverable">
                <div className="">
                  <img
                    src={PlaybackImg}
                    alt="Screenshot of CoderTest Interviewer Notes"
                    className="custom-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="feature-5"
          className="section coral-white"
          sitemeta="[object Object]"
          activepage="Landing"
        >
          <div className="container-lrg flex">
            <div className="flex col-6 flex-column mobile-text-center center-vertical">
              <h3 className="bold primary-color launchaco-builder-hoverable">
                Question Library
              </h3>
              <p className="paragraph secondary-color launchaco-builder-hoverable">
                Standardize your interview process and save your questions in
                our library, then easily insert them into an interview.
              </p>
            </div>
            <div className="col-6 flex center-horizontal center-vertical">
              <div className="launchaco-builder-hoverable">
                <div className="">
                  <img
                    src={QuestionsLibraryImg}
                    alt="Screenshot of CoderTest Slack Integration example"
                    className="custom-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="feature-6"
          className="section coral-white"
          sitemeta="[object Object]"
          activepage="Landing"
        >
          <div className="container-lrg flex">
            <div className="flex col-6 flex-column mobile-text-center center-vertical">
              <h3 className="bold primary-color launchaco-builder-hoverable">
                Slack Integration
              </h3>
              <p className="paragraph secondary-color launchaco-builder-hoverable">
                Get a notification into your chosen Slack channel each time a
                candidate completes an interview, with the name of the test, and
                a link to the interview playback.
              </p>
            </div>
            <div className="col-6 flex center-horizontal center-vertical">
              <div className="launchaco-builder-hoverable">
                <div className="">
                  <img
                    src={SlackWebhookImg}
                    alt="Screenshot of CoderTest Slack Integration example"
                    className="custom-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="pricing-4"
          className="section coral-white"
          sitemeta="[object Object]"
          activepage="Landing"
        >
          <div className="container text-center mb75">
            <div className="col-12 text-center">
              <h4 className="heading primary-color launchaco-builder-hoverable">
                Simple Pricing
              </h4>
              <p className="subheading secondary-color mt20 launchaco-builder-hoverable">
                We understand hiring happens in bursts, so no contracts!
              </p>
            </div>
          </div>
          <div className="container-lrg" style={{ maxWidth: "1250px" }}>
            <div className="col-12">
              <div className="card flex mobile-flex-wrap">
                <div className="col-3 pad30 flex flex-column spread launchaco-builder-hoverable">
                  <div className="mb20">
                    <h4 className="bold primary-color">Developer</h4>{" "}
                    <b className="heading primary-color">Free</b>{" "}
                    <span className="paragraph secondary-color" />
                    <ul className="mt20">
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          2 interviews
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          $10 per interview in excess of 2
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Unlimited Team Members
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Unlimited Question Templates
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Slack Integration
                        </span>
                      </li>
                    </ul>
                  </div>{" "}
                  <a href="https://app.codertest.io/coderoom/interviews"
                    className="button mobile-text-center mt10 launchaco-builder-hoverable button__full mobile-text-center accent-bg primary-color"
                  >
                    <span> Get Started </span>
                  </a>
                </div>
                <div className="col-3 pad30 flex flex-column spread launchaco-builder-hoverable">
                  <div className="mb20">
                    <h4 className="bold primary-color">Team</h4>{" "}
                    <b className="heading primary-color">$50</b>{" "}
                    <span className="paragraph secondary-color">Monthly</span>
                    <ul className="mt20">
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          20 interviews
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          $10 per interview in excess of 20
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Unlimited Team Members
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Unlimited Question Templates
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Slack Integration
                        </span>
                      </li>
                    </ul>
                  </div>{" "}
                  <a href="https://app.codertest.io/coderoom/interviews"
                    className="button mobile-text-center mt10 launchaco-builder-hoverable button__full mobile-text-center accent-bg primary-color"
                  >
                    <span> Get Started </span>
                  </a>
                </div>
                <div className="col-3 pad30 flex flex-column spread launchaco-builder-hoverable">
                  <div className="mb20">
                    <h4 className="bold primary-color">Business</h4>{" "}
                    <b className="heading primary-color">$250</b>{" "}
                    <span className="paragraph secondary-color">Monthly</span>
                    <ul className="mt20">
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          50 interviews / month
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          $10 per interview in excess of 50
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Unlimited Team Members
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Unlimited Question Templates
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Slack Integration
                        </span>
                      </li>
                    </ul>
                  </div>{" "}
                  <a href="https://app.codertest.io/coderoom/interviews"
                    className="button mobile-text-center mt10 launchaco-builder-hoverable button__full mobile-text-center accent-bg primary-color"
                  >
                    <span> Get Started </span>
                  </a>
                </div>
                <div className="col-3 pad30 flex flex-column spread launchaco-builder-hoverable">
                  <div className="mb20">
                    <h4 className="bold primary-color">Enterprise</h4>{" "}
                    <b className="heading primary-color">Contact Us</b>{" "}
                    <div className="paragraph secondary-color">
                      Starting at $750/month
                    </div>
                    <ul className="mt20">
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Custom interviews / month
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Unlimited user accounts
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Unlimited Team Members
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Unlimited Question Templates
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          SAML single-sign-on
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Slack Integration
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          API Integration
                        </span>
                      </li>
                      <li className="flex mb20">
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          className="svg-fill noshrink mr20"
                        >
                          <g transform="translate(4.000000, 5.000000)">
                            <path d="M5.24961475,8.39956394 L2.16512063,5.35475362 C1.74038521,4.93548271 1.05017933,4.9352057 0.624646383,5.35526395 C0.199019838,5.77541456 0.198881924,6.45614266 0.624129379,6.8759191 L4.35212111,10.555948 C4.38658274,10.6034965 4.42544251,10.6488955 4.46870038,10.6915969 C4.70907746,10.9288814 5.03375662,11.0320952 5.3475228,11.0013023 C5.59592563,10.9812599 5.83876209,10.8774981 6.02880771,10.6898975 C6.06831079,10.6509027 6.10414872,10.6096632 6.13632157,10.5665961 L13.9850992,2.81879759 C14.4107939,2.39857976 14.410861,1.71746456 13.985328,1.29740632 C13.5597015,0.8772557 12.8697673,0.877449143 12.444108,1.29763217 L5.24961475,8.39956394 Z" />
                          </g>
                        </svg>{" "}
                        <span className="span secondary-color">
                          Dedicated account manager
                        </span>
                      </li>
                    </ul>
                  </div>{" "}
                  <Link
                    to="/sales"
                    className="button mobile-text-center mt10 launchaco-builder-hoverable button__full mobile-text-center accent-bg primary-color"
                    style={{ backgroundColor: "#F78764" }}
                  >
                    <span> Contact Us</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Landing;
