import React from "react";
import { Link } from "react-router-dom";

const Footer = props => {
  const { theme } = props;
  return (
    <React.Fragment>
      <footer
        id="footer-15"
        className={`section text-center ${theme ? theme : "coral-black"}`}
        sitemeta="[object Object]"
        activepage="Landing"
      >
        <div className="container col-12">
          <nav className="mb50">
            <Link to="/privacy" className="nav-link secondary-color">
              Privacy
            </Link>
            <Link to="/support" className="nav-link secondary-color">
              Support
            </Link>
            <Link
              to="/codertest-slack-integration"
              className="nav-link secondary-color"
            >
              Slack
            </Link>
            <Link to="/" className="nav-link secondary-color">
              CoderTest
            </Link>
            <Link to="/coderun" className="nav-link secondary-color">
              CodeRun
            </Link>
          </nav>

          <div className="mt50">
            <span className="span secondary-color">©</span>
            <span className="span secondary-color">2019 CoderTest</span>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
