import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "antd";

const Header = props => {
  let { type } = props;
  const data = {
    CodeRun: {
      title: "CodeRun",
      titleLink: "/coderun",
      login: "https://coderun.codertest.io/login",
      signup: "https://coderun.codertest.io/register"
    },
    CoderTest: {
      title: "CoderTest",
      titleLink: "/",
      login: "https://app.codertest.io/login",
      signup: "https://app.codertest.io/register"
    }
  };
  type = type || "CoderTest";
  return (
    <React.Fragment>
      <header className="header">
        <div className="container-lrg">
          <div className="flex col-12 spread">
            <Link
              className="logo primary-color launchaco-builder-hoverable logo"
              to={data[type].titleLink}
            >
              {data[type].title}
            </Link>

            <a href="#main-menu" id="menu" className="menu-toggle">
              <b className="nav-link mr0 secondary-color">☰</b>
            </a>
            <nav id="main-menu" role="navigation" className="main-menu">
              <a href="#" id="main-menu-close" className="menu-close">
                <span className="bold">✕</span>
              </a>

              <a href={data[type].login} className="nav-link secondary-color">
                Log in
              </a>

              <a href={data[type].signup} className="nav-link secondary-color">
                <span>
                  Sign up{" "}
                  <Icon type="arrow-right" style={{ verticalAlign: "sub" }} />
                </span>
              </a>
            </nav>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
