import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "antd";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import AddToSlackBtn from "../../img/btn-add-to-slack.svg";
import SlackWebhookImg from "../../img/slack-webhook.png";


const steps = [
  { icon: "login", text: "Login to CoderTest" },
  {
    icon: "branches",
    text: `Click on the "Integrations" tab on the side menu`
  },
  {
    icon: "select",
    text: `Scroll down to “Slack” and click on the “Connect” button`
  },
  {
    icon: "slack",
    text: `Choose the Slack channel you want CoderTest to post to and then hit authorize`
  }
];
class SlackLanding extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="font-modern">
          <div className="coral-black">
            <Header />
            <section className="section">
              <div className="container mb40">
                <div className="col-12 text-center">
                  <h1 className="heading-lrg primary-color launchaco-builder-hoverable">
                    CoderTest + Slack
                  </h1>
                  <h2 className="subheading secondary-color mt20 launchaco-builder-hoverable">
                    Manage candidates where you collaborate.
                  </h2>
                  <div
                    className="mt40"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Link to="/integrations">
                      <img
                        src={AddToSlackBtn}
                        alt="Add to Slack Button"
                        className="mask-img"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="feature-0"
              className="section coral-white"
              sitemeta="[object Object]"
              activepage="Landing"
            >
              <div className="container-lrg flex">
                <div className="flex col-6 flex-column mobile-text-center center-vertical">
                  <h3 className="bold primary-color launchaco-builder-hoverable">
                    Check Results
                  </h3>
                  <p className="paragraph secondary-color launchaco-builder-hoverable">
                    You'll get a notification into your chosen Slack channel
                    each time a candidate completes an interview, with the name
                    of the test, and a link to the interview playback.
                  </p>
                </div>
                <div className="col-6 flex center-horizontal center-vertical">
                  <div className="launchaco-builder-hoverable">
                    <div
                      style={{
                        borderRadius: "8px",
                        border: "1px solid #dadada",
                        boxShadow: "0 3px 0 0 #dadada",
                        display: "flex",
                        paddingTop: "10px"
                      }}
                    >
                      <img
                        src={SlackWebhookImg}
                        alt="Screenshot of CoderTest Interviewer Notes"
                        className="custom-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="pricing-4"
              className="section coral-white"
              sitemeta="[object Object]"
              activepage="Landing"
            >
              <div className="container text-center mb75">
                <div className="col-12 text-center">
                  <h4 className="heading primary-color launchaco-builder-hoverable">
                    How do I set up the Slack integration with CoderTest?
                  </h4>
                </div>
              </div>
              <div
                className="container-lrg flex"
                style={{ justifyContent: "space-around" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {steps.map(step => (
                    <div key={step.text} style={{ width: "20vw" }}>
                      <div style={{ display: "grid", textAlign: "center" }}>
                        <Icon type={step.icon} style={{ fontSize: "6vh" }} />
                        <p
                          className="paragraph secondary-color launchaco-builder-hoverable"
                          style={{ marginTop: "20px" }}
                        >
                          {step.text}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            <section
              id="cta-5"
              className="section coral-white"
              sitemeta="[object Object]"
              activepage="Landing"
            >
              <div className="container text-center">
                <div className="col-12">
                  <h4 className="heading-lrg primary-color launchaco-builder-hoverable">
                    Ready to improve your recruiting process?
                  </h4>
                  <p className="paragraph mt20 secondary-color mt20 launchaco-builder-hoverable" />
                  <div className="mt40">
                    <Link
                      to="/coderoom/interviews"
                      className="button mobile-text-center mt10 launchaco-builder-hoverable mobile-text-center accent-bg fill-white primary-color"
                    >
                      <span> Try CoderTest For Free </span>
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SlackLanding;
