import React from "react";

const Support = () => (
  <React.Fragment>
    <section className="section">
      <div className="container mb40">
        <div className="col-12 ">
          <h1 className="heading-lrg primary-color text-center ">Support</h1>
          <div className="mt40">
            <h4 className="heading-sml primary-color ">
              Have questions? Need help?
            </h4>
            <p className="paragraph mt10 mb10">
              {"Feel free to reach out to us at "}
              <a href="mailto:support@notifime.app">support@codertest.io</a>.
            </p>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default Support;
