import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Link } from "react-router-dom";

const supportedLanguages = [
  { language: "Bash", environment: "Running GNU bash 4.4" },
  { language: "C", environment: "Running GCC 8.2" },
  { language: "C#", environment: "Running .NET Core 2.2" },
  { language: "C++", environment: "Running GCC 8.2" },
  { language: "Elixir", environment: "Running Elixir 1.6" },
  { language: "F#", environment: "Running .NET Core 2.2" },
  { language: "Golang", environment: "Running Go 1.10" },
  { language: "Java", environment: "Running OpenJDK 11." },
  { language: "JavaScript", environment: "Running NodeJS v10." },
  { language: "PHP", environment: "Running Zend PHP v7.2" },
  { language: "Python2", environment: "Running Python 2.7" },
  { language: "Python3", environment: "Running Python 3.6" },
  { language: "Ruby", environment: "Running Ruby 2.6" },
  { language: "Scala", environment: "Running Scala 2.12" }
];
const Landing = () => (
  <React.Fragment>
    <div className="black-flat">
      <Header type="CodeRun" />
      <section className="section">
        <div className="container mb40">
          <div className="col-12 text-center">
            <h1 className="heading-lrg primary-color launchaco-builder-hoverable">
              Code Evaluation API
            </h1>
            <h2 className="subheading secondary-color mt20 launchaco-builder-hoverable">
              Compile, run, and eval your code in a sandboxed evironment.
            </h2>
            {/* <div className="mt40">
              <form
                style={{ maxWidth: "550px" }}
                action=""
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                novalidate="novalidate"
                className="container-sml mailchimp flex mb20"
              >
                <input
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  placeholder="Your Email Address"
                  required="required"
                  className="mailchimp-input mobile-text-center"
                />
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="white-color button no-max-width accent-bg primary-color"
                  value="Get Notified When We're Live!"
                />
              </form>
            </div> */}
            <div className="mt40">
              <form
                style={{ maxWidth: "550px" }}
                action="https://codertest.us7.list-manage.com/subscribe/post?u=d14509751ba19f167dfe845a9&amp;amp;id=17984c4476"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                novalidate="novalidate"
                className="container-sml mailchimp flex mb20"
                style={{ padding: "0px" }}
              >
                <input
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  placeholder="Your Email Address"
                  required="required"
                  className="mailchimp-input mobile-text-center"
                />
                <div
                  aria-hidden="true"
                  style={{ position: "absolute", left: "-5000px" }}
                >
                  <input
                    type="text"
                    name="b_3198ee66c8baf4d60aa2fee6b_d60e1830e8"
                    tabindex="-1"
                  />
                </div>
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="white-color button no-max-width accent-bg primary-color"
                  value="Get Notified When We're Live!"
                />
              </form>
            </div>
          </div>
        </div>
        <div className="container mt75">
          <div className="col-12">
            <div className="codeblock">
              <pre>
                <code className="hljs javascript" style={{ height: "400px" }}>
                  <table className="hljs-ln">
                    <tbody>
                      <tr>
                        <td className="hljs-ln-numbers">
                          <div
                            className="hljs-ln-line hljs-ln-n"
                            data-line-number="1"
                          />
                        </td>
                        <td className="hljs-ln-code">
                          <div className="hljs-ln-line">
                            $ curl https:
                            <span className="hljs-comment">
                              {`//coderun.codertest.io/api/v1/evaluate \\`}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="hljs-ln-numbers">
                          <div
                            className="hljs-ln-line hljs-ln-n"
                            data-line-number="2"
                          />
                        </td>
                        <td className="hljs-ln-code">
                          <div className="hljs-ln-line">
                            {" "}
                            -H{" "}
                            <span className="hljs-string">
                              "Authorization: Bearer YOUR_API_KEY"
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="hljs-ln-numbers">
                          <div
                            className="hljs-ln-line hljs-ln-n"
                            data-line-number="3"
                          />
                        </td>
                        <td className="hljs-ln-code">
                          <div className="hljs-ln-line">
                            {" "}
                            -d language=javascript \
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="hljs-ln-numbers">
                          <div
                            className="hljs-ln-line hljs-ln-n"
                            data-line-number="4"
                          />
                        </td>
                        <td className="hljs-ln-code">
                          <div className="hljs-ln-line">
                            {" "}
                            -d code=
                            <span className="hljs-built_in">console</span>
                            .log(<span className="hljs-string">'Hello'</span>) \
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="hljs-ln-numbers">
                          <div
                            className="hljs-ln-line hljs-ln-n"
                            data-line-number="5"
                          />
                        </td>
                        <td className="hljs-ln-code">
                          <div className="hljs-ln-line">
                            {" "}
                            -d timeout=<span className="hljs-number">30</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </code>
              </pre>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section
      id="feature-1"
      className="section black-white"
      sitemeta="[object Object]"
      activepage="Landing"
    >
      <div className="container-lrg flex">
        <div className="col-4 text-center flex flex-column center-horizontal">
          <i className="icon mobile-center-icon secondary-bg launchaco-builder-hoverable">
            <svg
              className="svg-fill"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25 25"
            >
              <path d="M16.854 19.146a.5.5 0 0 1 0 .707l-4 4a.5.5 0 0 1-.707 0l-4-4a.5.5 0 0 1 .706-.707L12 22.293V10.5a.5.5 0 0 1 1 0v11.793l3.147-3.147a.5.5 0 0 1 .707 0zM19.928 6.08A7.34 7.34 0 0 0 12.5 0a7.34 7.34 0 0 0-7.428 6.08A5.996 5.996 0 0 0 6 18h1.5a.5.5 0 0 0 0-1H6a4.996 4.996 0 0 1-.774-9.933l.733-.114.104-.734A6.356 6.356 0 0 1 12.5 1a6.356 6.356 0 0 1 6.438 5.219l.103.734.733.114A4.996 4.996 0 0 1 19 17h-1.5a.5.5 0 0 0 0 1H19a5.996 5.996 0 0 0 .928-11.92z" />
            </svg>
          </i>
          <h3 className="bold primary-color launchaco-builder-hoverable">
            Powered by the Cloud
          </h3>
          <p className="paragraph secondary-color launchaco-builder-hoverable">
            Execute code from any device and platform.
          </p>
        </div>
        <div className="col-4 text-center flex flex-column center-horizontal">
          <i className="icon mobile-center-icon secondary-bg launchaco-builder-hoverable">
            <svg
              className="svg-fill"
              viewBox="0 0 8 15"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7.75 7.46c.247.117.305.378.12.593l-5.633 6.602c-.143.168-.209.123-.15-.087l1.395-5.034.03-.111a.373.373 0 0 0-.194-.425l-.103-.05-2.969-1.41C0 7.421-.06 7.16.122 6.944L5.694.327c.142-.169.21-.124.154.087L4.516 5.473l-.028.11a.374.374 0 0 0 .195.42l.102.049L7.75 7.46z" />
            </svg>
          </i>
          <h3 className="bold primary-color launchaco-builder-hoverable">
            Multiple Languages
          </h3>
          <p className="paragraph secondary-color launchaco-builder-hoverable">
            We support 14 programming languages.
          </p>
        </div>
        <div className="col-4 text-center flex flex-column center-horizontal">
          <i className="icon mobile-center-icon secondary-bg launchaco-builder-hoverable">
            <svg
              className="svg-fill"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25 25"
            >
              <path d="M12.5 0A12.5 12.5 0 1 0 25 12.5 12.5 12.5 0 0 0 12.5 0zm4.353 16.853a.5.5 0 0 1-.707 0l-4-4-.004-.005a.497.497 0 0 1-.142-.346V6.5a.5.5 0 0 1 1 0v5.793l3.853 3.853a.5.5 0 0 1 0 .707z" />
            </svg>
          </i>
          <h3 className="bold primary-color launchaco-builder-hoverable">
            Per-second billing
          </h3>
          <p className="paragraph secondary-color launchaco-builder-hoverable">
            You only pay for the compute time you use.
          </p>
        </div>
      </div>
    </section>
    <section
      id="checklist-3"
      className="section black-white"
      sitemeta="[object Object]"
      activepage="Landing"
    >
      <div className="container text-center mb75">
        <div className="col-12">
          <h3 className="heading primary-color launchaco-builder-hoverable">
            Supported Languages
          </h3>
        </div>
      </div>
      <div className="container flex">
        <ul className="checklist col-12">
          {supportedLanguages.map(({ language, environment }, index) => (
            <li
              key={index}
              className="checklist-item flex center-horizontal spread pad20 launchaco-builder-hoverable"
            >
              <p className="bold primary-color mr20">{language}</p>
              <p>{environment}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="container text-center mb75">
        <div className="col-12">
          <h3 className="heading primary-color launchaco-builder-hoverable">
            ...And More!
          </h3>
          <h3 className="subheading secondary-color mt20 launchaco-builder-hoverable">
            We're adding support for more languages every day!
          </h3>
        </div>
      </div>
    </section>
    <section
      id="pricing-4"
      className="section black-white"
      sitemeta="[object Object]"
      activepage="Landing"
    >
      <div className="container text-center mb75">
        <div className="col-12 text-center">
          <h4 className="heading primary-color launchaco-builder-hoverable">
            Simple On-Demand Pricing
          </h4>
          <p className="subheading secondary-color mt20 launchaco-builder-hoverable">
            You only pay for what you use!
          </p>
          <p className="subheading secondary-color mt20 launchaco-builder-hoverable">
            Usage is billed on one second increments.
          </p>
        </div>
      </div>
      <div className="container flex center-vertical">
        <div className="col-8">
          <div className="card pad30 launchaco-builder-hoverable">
            <div className="mb20">
              <div className="text-center">
                <div className="mt10">
                  <b className="heading primary-color">$0.01</b>{" "}
                  <span className="paragraph secondary-color">
                    / second of execution
                  </span>
                </div>
              </div>
            </div>{" "}
            {/* s */}
          </div>
        </div>
      </div>
    </section>
    <section
      id="cta-5"
      className="section black-white"
      sitemeta="[object Object]"
      activepage="Landing"
    >
      <div className="container text-center">
        <div className="col-12">
          <h4 className="heading-lrg primary-color launchaco-builder-hoverable">
            Interested! Put your email down to get notified when we're live!
          </h4>
          <p className="paragraph mt20 secondary-color mt20 launchaco-builder-hoverable" />
          <div className="mt40">
            <form
              action="https://codertest.us7.list-manage.com/subscribe/post?u=d14509751ba19f167dfe845a9&amp;amp;id=17984c4476"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              target="_blank"
              novalidate="novalidate"
              className="container-sml mailchimp flex mb20"
              style={{ padding: "0px" }}
            >
              <input
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                placeholder="Your Email Address"
                required="required"
                className="mailchimp-input mobile-text-center"
              />
              <div
                aria-hidden="true"
                style={{ position: "absolute", left: "-5000px" }}
              >
                <input
                  type="text"
                  name="b_3198ee66c8baf4d60aa2fee6b_d60e1830e8"
                  tabindex="-1"
                />
              </div>
              <input
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="white-color button no-max-width accent-bg primary-color"
                value="Get Notified!"
              />
            </form>
          </div>
        </div>
      </div>
    </section>
    <Footer theme="black-flat" />
  </React.Fragment>
);

export default Landing;
