import React, { Component } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";

import { Redirect } from "react-router";

import CodeRunLanding from "./pages/CodeRun/Landing";

import Landing from "./pages/CoderTest/Landing";
import Support from "./pages/CoderTest/Support";
import Privacy from "./pages/CoderTest/Privacy";
import SlackLanding from "./pages/CoderTest/Slack";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="font-modern">
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/support" component={Support} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/company/:name" component={Landing} />
            <Route
              exact
              path="/codertest-slack-integration"
              component={SlackLanding}
            />
            <Route exact path="/coderun" component={CodeRunLanding} />
            <Redirect to="/" />
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
